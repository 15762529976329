import {Component, Inject} from '@angular/core';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {LocalizationService} from '@kisc/libs/commons';
import {appApplyTheme, UI_KIT_LIGHT} from '@kisc/libs/ui-kit';
import dayjs from 'dayjs';
import {filter} from 'rxjs';

@Component({
    selector: 'coid-root',
    templateUrl: './app.component.html',
})
export class AppComponent {
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private localizationService: LocalizationService,
        @Inject(MAT_DATE_LOCALE) private locale: string,
        private dateAdapter: DateAdapter<any>,
    ) {
        this.localizationService.defaultInitialization();
        appApplyTheme(UI_KIT_LIGHT);
        this.matIconRegistry.addSvgIconSet(this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/temp-cached/sprites/sprite-defs.svg`));
        this.localizationService.language$
            .pipe(filter(language => language !== null))
            .subscribe(language => {
                this.locale = language.locale;
                this.dateAdapter.setLocale(this.locale);
                dayjs.locale(language.locale);
            });
    }
}
