import {Injectable} from '@angular/core';
import {AuthType} from '@kisc/coid-frontend-api';
import {RouterService, SentryService} from '@kisc/libs/commons';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Path} from '../path';
import {AUTH_TOKEN, AUTH_TYPE, ESIGN_REVOKE_TOKEN, USER_DATA} from './browser-storage.keys';

@Injectable({
    providedIn: 'root',
})
export class AuthHolderService {
    authType: AuthType;
    authToken: string;
    revokeToken: string;
    userData: UserData;

    constructor(
        private localStorageService: LocalStorageService,
        private sessionStorage: SessionStorageService,
        private routerService: RouterService,
        private sentryService: SentryService,
    ) {
        const token = this.localStorageService.retrieve(AUTH_TOKEN);
        this.revokeToken = this.sessionStorage.retrieve(ESIGN_REVOKE_TOKEN);
        if (token) {
            this.authToken = token;
            this.userData = this.localStorageService.retrieve(USER_DATA);
            this.sentryService.setUser(this.userData.iinEncrypted);
        }
        this.authType = this.localStorageService.retrieve(AUTH_TYPE);
    }

    public get isUser(): boolean {
        return this.userData.role === Role.USER;
    }

    public get isOrganization(): boolean {
        return [Role.HEAD_OF_ORGANIZATION, Role.ORGANIZATION_EMPLOYEE].includes(this.userData.role as Role);
    }

    public get isModerator(): boolean {
        return this.userData.role === Role.MODERATOR;
    }

    public get role(): string {
        return this.userData.role;
    }

    storeAuthToken(token: string): void {
        this.authToken = token;
        this.localStorageService.store(AUTH_TOKEN, token);
    }

    storeRevokeToken(token: string): void {
        this.revokeToken = token;
        this.sessionStorage.store(ESIGN_REVOKE_TOKEN, token);
    }

    clearRevokeToken(): void {
        this.revokeToken = null;
        this.sessionStorage.clear(ESIGN_REVOKE_TOKEN);
    }

    storeAuthType(authType: AuthType): void {
        this.authType = authType;
        this.localStorageService.store(AUTH_TYPE, authType);
    }

    storeUserData(userData: UserData): void {
        this.userData = userData;
        this.localStorageService.store(USER_DATA, userData);
        this.sentryService.setUser(userData.iinEncrypted);
    }

    logout(): void {
        let path;
        if (this.isUser) {
            path = Path.USER;
        } else if (this.isModerator) {
            path = Path.MODERATOR;
        } else {
            path = Path.ORGANIZATION;
        }
        this.clearData();
        this.routerService.navigate(path);
    }

    private clearData(): void {
        this.localStorageService.clear(AUTH_TOKEN);
        this.localStorageService.clear(AUTH_TYPE);
        this.localStorageService.clear(USER_DATA);
        this.authToken = null;
        this.userData = null;
        this.authType = null;
        this.sentryService.resetUser();
    }
}

export interface UserData {
    userId: number;
    fullName: string;
    bin: string;
    organizationName: string;
    iin: string;
    iinEncrypted: string;
    role: Role;
    phone?: string;
}

export enum Role {
    HEAD_OF_ORGANIZATION = 'HEAD_OF_ORGANIZATION',
    ORGANIZATION_EMPLOYEE = 'ORGANIZATION_EMPLOYEE',
    MODERATOR = 'MODERATOR',
    USER = 'USER'
}
