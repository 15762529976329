export const PROFILE_PAGE_VIEW = 'PROFILE_PAGE_VIEW';
export const PROFILE_SIGN_OUT = 'PROFILE_SIGN_OUT';
export const PROFILE_SESSION_EXPIRED = 'PROFILE_SESSION_EXPIRED';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const ESIGN_REVOKE_STARTED = 'ESIGN_REVOKE_STARTED';
export const ESIGN_REVOKE_WRONG_PASSWORD = 'ESIGN_REVOKE_WRONG_PASSWORD';
export const ESIGN_REVOKE_ERROR = 'ESIGN_REVOKE_ERROR';
export const ESIGN_REVOKE_REJECT = 'ESIGN_REVOKE_REJECT';
export const ESIGN_PASSWORD_RESET = 'ESIGN_PASSWORD_RESET';
export const ESIGN_REVOKE_SUCCEED = 'ESIGN_REVOKE_SUCCEED';
