import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {CommonErrorHandler, PosthogService, RouterService} from '@kisc/libs/commons';
import {AuthHolderService} from '../domain/auth-holder.service';
import {PROFILE_ERROR, PROFILE_SESSION_EXPIRED} from './analytics-events';

@Injectable()
export class AppErrorHandler extends CommonErrorHandler {
    constructor(
        zone: NgZone,
        routerService: RouterService,
        private authHolderService: AuthHolderService,
        private posthogService: PosthogService,
    ) {
        super(zone, routerService);
    }

    handleAccessError(error: HttpErrorResponse): void {
        if (this.authHolderService.isUser) {
            this.posthogService.capture(PROFILE_SESSION_EXPIRED);
        }
        this.authHolderService.logout();
    }

    handleServerError(error: any): void {
        if (this.authHolderService.isUser) {
            const url = new URL(window.location.href);
            this.posthogService.capture(PROFILE_ERROR, {drop_path: url.pathname, reason: error});
        }
        super.handleServerError(error);
    }
}

export enum Error {
    PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
    SERVER_ERROR = 'SERVER_ERROR',
    MODERATOR_FORBIDDEN = 'MODERATOR_FORBIDDEN'
}
